<template>
  <div class="my-order" v-if="data">
    <Header />
    <div class="h-24"></div>
    <!-- <div class="breadcrumbs">
      <div class="container py-4 px-4 flex items-center uppercase is-size-6">
        <span class="flex items-center">
          <router-link :to="{ name: 'Home' }" class="has-text-grey"
            >Home</router-link
          >
          <span class="mx-2">/</span>
          <router-link :to="{ name: 'My Orders' }" class="has-text-grey"
            >My Orders</router-link
          >
          <span class="mx-2">/</span>
          <router-link :to="{ name: 'My Order' }" class="has-text-grey">{{
            data ? data.code : ""
          }}</router-link>
        </span>
      </div>
    </div> -->
    <b-taglist class="breadcrumbs container p-4">
      <b-tag size="is-large" rounded type="is-white">
        <router-link :to="{ name: 'Home' }" class="has-text-grey">
          <b-icon icon="home" type="is-grey"></b-icon>
        </router-link>
      </b-tag>
      <b-tag size="is-large" rounded type="is-white">
        <router-link :to="{ name: 'My Orders' }" class="has-text-grey">
          My Orders
        </router-link>
      </b-tag>
      <b-tag size="is-large" rounded type="is-light">
        <router-link :to="{ name: 'My Order' }" class="has-text-grey">
          {{ data ? data.code : "" }}
        </router-link>
      </b-tag>
    </b-taglist>
    <div class="container p-4">
      <div class="flex items-center justify-between">
        <!-- <div class="is-size-4 has-text-grey mb-4" v-if="data">
          {{ data.code }}
        </div> -->
      </div>
      <div class="px-2">
        <div class="-mx-4 flex flex-grow flex-wrap">
          <div class="w-1/4 px-2">
            <div class="shadow-xs p-4">
              <div class="heading">Details</div>
              <div class="">
                <p class="">
                  Order Number: <span class="">{{ data.code }}</span>
                </p>
                <p class="">
                  Date Created: <span class="">{{ data.created_at }}</span>
                </p>
                <p class="">
                  Date Updated: <span class="">{{ data.updated_at }}</span>
                </p>
                <p class="">
                  Total Amount:
                  <span class="">$ {{ data.amount.toFixed(2) }}</span>
                </p>
                <p class="">
                  Status: <span class="">~{{ data.completion }}%</span>
                </p>
              </div>
            </div>
          </div>
          <div class="w-3/4 px-2">
            <div class="shadow-xs p-4">
              <div class="heading mb-2">Products</div>
              <div class="products">
                <div
                  class="px-2 mb-4 has-background-white"
                  v-for="item in data.products"
                  :key="item.id"
                >
                  <div class="-mx-2 flex flex-wrap items-center">
                    <div class="w-auto px-2">
                      <img
                        width="60"
                        :src="
                          item.image ? item.image.thumb : $default_image_thumb
                        "
                        loading="lazy"
                      />
                    </div>
                    <div class="w-1/3 px-2">
                      {{ item.name }}
                      <br />
                      <small>
                        {{ item.code }}
                      </small>
                    </div>
                    <div class="px-2">{{ item.pivot.quantity }} pcs.</div>
                    <div class="w-1/4 px-2 has-text-right">
                      $ {{ item.pivot.price }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full px-2 mt-4">
            <div class="shadow-xs p-4">
              <div class="heading mt-4 mb-2">Documents</div>
              <div class="files" v-if="data.files.length">
                <div class="px-2 is-relative">
                  <div
                    ref="files-container"
                    class="-mx-4 flex flex-wrap files-container"
                  >
                    <div
                      class="w-1/6 px-2 mb-4"
                      v-for="(file, index) in data.files"
                      :key="index"
                    >
                      <a
                        :href="file.full"
                        target="_blank"
                        class="file-item block shadow-xs"
                      >
                        <b-image
                          :src="
                            file.type == 'document'
                              ? $default_document_thumb
                              : file.thumb
                          "
                          :alt="file.filename"
                          ratio="1by1"
                          lazy
                        ></b-image>
                        <p class="p-2 break-all is-size-7">
                          {{ file.filename }}
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="any" v-else>No documents found.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/client/Header";
import store from "@/store";
export default {
  title: () => {
    return `Order - ${process.env.VUE_APP_TITLE}`;
  },
  data: () => ({
    data: null
  }),
  components: {
    Header
  },
  beforeRouteEnter(to, from, next) {
    if (to.meta.dispatch) {
      store
        .dispatch(to.meta.dispatch, { id: to.params.id })
        .then(res => {
          next(vm => {
            vm.data = res.data;
          });
        })
        .catch(() => {
          next({ name: "My Orders" });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
td {
  vertical-align: middle;
}

.my-order {
  min-height: 100vh;
  .is-empty {
    min-height: calc(100vh - 12rem);
  }
}
</style>
